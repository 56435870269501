<template>
  <div class="page">
    <header>
      <img src="../assets/images/logo-top.png" alt="" />

      <nav>
        <a href="#home">Home</a>
        <a href="#service">Service</a>
        <a href="#about">Contact us</a>
      </nav>
    </header>
    <div class="home" id="home">
      <img src="../assets/images/logo.png" alt="" />
      <div class="br"></div>
      <p>The leading cross-border digital marketing group in China</p>
    </div>

    <div id="service">
      <div class="company">
        <img class="title" src="../assets/images/company.png" alt="" />
        <div class="companyList">
          <div class="item">
            <img src="../assets/images/item1.png" alt="" />
            <div class="tit">Insight on overseas markets</div>
            <div class="content">
              Industrial and media trends reports Overseas user behavioral
              studies Overseas competitors analyses
            </div>
          </div>
          <div class="item">
            <img src="../assets/images/item2.png" alt="" />
            <div class="tit">Content marketing on social media</div>
            <div class="content">
              Social media marketing Overseas PR services Overseas influencer
              marketing
            </div>
          </div>
          <div class="item">
            <img src="../assets/images/item3.png" alt="" />
            <div class="tit">Integrated marketing strategy</div>
            <div class="content">
              Brand marketing strategy Media launch strategy Result-oriented
              marketing strategy
            </div>
          </div>
        </div>
        <div class="product">
          <img src="../assets/images/product.png" alt="" />
          <div class="right">
            <div class="tit">
              The latest technology from Silicon Valley drives global digital
              marketing services
            </div>
            <div class="content">
              - Returnee background + foreign experts, international marketing
              team, support nearly 10 languages including Spanish, Russian,
              Japanese, French, Korean, etc. - One-stop service for advertising
              optimization such as strategy, optimization, creativity, content
              marketing, data analysis, etc. - All our team members hold
              official certifications such as Facebook Blueprint, Google
              Advertising Professional, Twitter Flight School, etc. - Average of
              more than three years of advertising experience
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="service">
      <div class="tit">Global Expertise Digital Marketing Services Team</div>
      <div class="content">
        Provide cross-platform overseas marketing services Help you improve your
        marketing effectiveness Achieving global marketing goals.
      </div>
      <div class="inp">
        <input placeholder="Enter your e-mail address" type="email" />
        <div class="btn" @click="sub">Subscribe</div>
      </div>
    </div>
    <footer id="about">
      <p class="title">Contact us</p>

      <div class="contact">
        <div class="item">
          <p>
            Address:FLAT/ROOM 1101D，11/F，LIPPO SUN PLAZA，28 CANTON ROAD,TSIM SHA TSUI,KOWLOON，HONGKONG
          </p>
        </div>
        <div class="item">
          <p>
            Email:pt.apijayaabadi2018@gmail.com
          </p>
        </div>
      </div>
      
    </footer>
    <p class="end">
      Copyright © Wavetec All Rights Reserved.
    </p>
  </div>
</template>
<style lang="less">
a {
  text-decoration: none;
}

header {
  padding: 0 230px;
  height: 80px;
  line-height: 80px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  z-index: 1000000000000;

  img {
    width: 187px;
    height: 35px;
  }

  nav {
    height: 80px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;

    a {
      width: 150px;
      height: 50px;
      line-height: 50px;
      border-radius: 25px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #3b4eee;
    }

    a:hover {
      background: #3b4eee;
      border-radius: 25px;
      color: #fff;
      transition: 0.3s;
    }
  }
}

.home {
  background: url(../assets/images/banner.png) no-repeat;
  background-size: 100%;
  width: 100%;
  height: 700px;
  overflow: hidden;
  text-align: center;
  img {
    width: 464px;
    height: 120px;
    margin-top: 270px;
  }
  .br {
    width: 350px;
    height: 4px;
    background-color: #fff;
    margin: 20px 0;
    margin-left: 770px;
  }
  p {
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    font-size: 43px;
    color: #eef1f4;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #eef1f4;
    line-height: 82px;
  }
}

.product {
  .productlist {
    padding-left: 170px;
    overflow: hidden;

    .productitem {
      width: 540px;
      margin: 40px 120px;
      float: left;
      height: 300px;
      text-align: center;
      background: #ffffff;
      box-shadow: 0 15px 30px 0 rgba(220, 220, 220, 0.3);
      border-radius: 30px;

      img {
        width: 180px;
        height: 180px;
      }

      .itemmsg {
        font-family: Helvetica;
        font-size: 20px;
        color: #333333;
        line-height: 40px;
        margin-top: 30px;
      }
    }
  }
}

.company {
  width: 100%;
  text-align: center;
  margin-top: 100px;
  height: 1600px;
  .title {
    width: 463px;
    height: 222px;
  }

  .companyList {
    margin-top: 100px;
    padding: 0 230px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .item {
      padding: 20px;
      width: 387px;
      height: 450px;
      box-shadow: 0px 4px 70px 0px rgba(91, 91, 91, 0.07);
      img {
        width: 116px;
        height: 116px;
      }
      .tit {
        font-size: 24px;
        font-family: Nunito Sans-Bold, Nunito Sans;
        font-weight: bold;
        color: #27292e;
      }
      .content {
        font-size: 18px;
        font-family: Nunito Sans-Regular, Nunito Sans;
        font-weight: 400;
        color: #5f626b;
        margin-top: 60px;
      }
    }
  }
  .product {
    margin-top: 100px;
    overflow: hidden;
    img {
      float: left;
      width: 800px;
    }
    .right {
      float: left;
      margin-left: 125px;
      width: 750px;
      .tit {
        font-size: 36px;
        font-family: Nunito Sans-ExtraBold, Nunito Sans;
        font-weight: 800;
        color: #27292e;
        line-height: 54px;
        margin-top: 100px;
      }
      .content {
        font-size: 18px;
        font-family: Nunito Sans-Regular, Nunito Sans;
        font-weight: 400;
        color: #585c65;
        line-height: 32px;
        margin-top: 30px;
      }
    }
  }
}
.service {
  width: 1368px;
  height: 630px;
  margin-left: 288px;
  padding: 100px 175px;
  background: rgba(104, 116, 220, 0.07);
  text-align: center;
  margin-bottom: 100px;
  margin-top: 50px;
  .tit {
    font-size: 43px;
    font-family: Nunito Sans-ExtraBold, Nunito Sans;
    font-weight: 800;
    color: #27292e;
    line-height: 65px;
  }
  .content {
    font-size: 22px;
    font-family: Nunito Sans-Regular, Nunito Sans;
    font-weight: 400;
    color: #585c65;
    line-height: 39px;
    margin-top: 80px;
  }
  .inp {
    margin-top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      width: 562px;
      height: 73px;
      padding: 20px;
      font-size: 19px;
      font-family: Nunito Sans-Regular, Nunito Sans;
      font-weight: 400;
      color: #888;
    }
    .btn {
      width: 200px;
      height: 73px;
      background-color: #3b4eee;
      margin-left: 20px;
      line-height: 73px;
      font-size: 24px;
      color: #fff;
      cursor: pointer;
    }
  }
}
.about {
  width: 1920px;
  overflow: hidden;

  .aboutimg {
    /* background: url(../assets/images/about.png) no-repeat 100%; */
    background-size: 100%;
    width: 100%;
    overflow: hidden;
    height: 510px;

    p {
      text-align: center;
      font-size: 48px;
      font-family: Arial-Black, Arial;
      font-weight: 900;
      color: #213d52;
      margin: 95px 0;
    }
  }

  .office {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -255px;

    img {
      width: 350px;
      height: 490px;
      margin: 0 15px;
    }
  }

  .title {
    color: #fff;
    padding: 20px 0 60px 0;
    margin-top: 90px;
    width: 100%;
  }

  .msg {
    width: 1114px;
    height: 238px;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #5d707e;
    line-height: 33px;
    margin-left: 405px;
    margin-top: 70px;
  }
}
.end {
    font-size: 19px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 50px;
    letter-spacing: 1px;
    background-color: #fff;
    text-align: center;
  }
footer {
  overflow: hidden;
  background: #2f2f2f;
  color: #fff;
  padding: 0 370px;



  .title {
    font-size: 24px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
    line-height: 29px;
    text-align: center;
    margin: 70px 0;
  }

  .msg {
    font-family: Helvetica;
    font-size: 18px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 100px;
    padding-bottom: 60px;
    border-bottom: 1px dashed #979797;
  }

  .contact {
    width: 100%;
    margin: 70px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #ffffff;

    .item {
      width: 500px;

      p {
        font-size: 22px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
}
</style>
<script>
export default {
  name: "Home",
  data() {
    return {
      showmore: true,
    };
  },
  methods: {
    goGp() {},
    sub() {
      alert("Subscribe Success");
    },
  },
};
</script>
